<template>
    <data-table
        :data="dataset"
        :loading="loading"
        :buttons="buttons"
        :header="header"
        :responsive="true"
        class="table-sm-font"
        :auto-update="false"
        :lang="lang"
        :paging="true"
        :actions="true"
        @action="onAction"
    />
</template>

<script>
import DataTableBase from '../../../../../Components/DataTableBase.vue'

export default {
    name: 'DataTableBase',
    extends: DataTableBase,
    computed: {
        header () {
            return [
                {
                    text: this.$t('adminUsers.usersTable.id'),
                    data: 'id',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('adminUsers.usersTable.givenName'),
                    data: 'givenName',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('adminUsers.usersTable.sn'),
                    data: 'sn',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('adminUsers.usersTable.sAMAccountName'),
                    data: 'sAMAccountName',
                    filterable: true,
                    sortable: true
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'enable',
                    text: this.$t('adminUsers.usersTable.enable'),
                    variant: 'primary',
                    confirm: true,
                    confirmText: this.$t('adminUsers.usersTable.enableConfirmText'),
                    visibleIf (rowData) {
                        return !rowData.row.id
                    }
                },
                {
                    event: 'disable',
                    text: this.$t('adminUsers.usersTable.disable'),
                    variant: 'danger',
                    confirm: true,
                    confirmText: this.$t('adminUsers.usersTable.enableConfirmText'),
                    visibleIf (rowData) {
                        return !!rowData.row.id
                    }
                }
            ]
        }
    }
}
</script>
