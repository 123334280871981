<template>
    <div class="container my-5">
        <h1>{{$t('adminUsers.heading')}}</h1>
        <p>{{$t('adminUsers.subtitle')}}</p>

        <h3 class="mt-5 mb-2">{{$t('adminUsers.usersTable.title')}}</h3>
        <div class="bg-white shadow p-4 rounded">
            <loader-overlay
                :loading="saving"
            >
                <users-data-table
                    :dataset="users"
                    :loading="loading"
                    @enable="onEnable"
                    @disable="onDisable"
                />
            </loader-overlay>
        </div>
    </div>
</template>

<script>
import DataViewBase from '../../../../Components/DataViewBase.vue'
import UsersDataTable from './Components/UsersDataTable.vue'

export default {
    name: 'UsersView',
    extends: DataViewBase,
    components: {
        UsersDataTable
    },
    data () {
        return {
            users: [],
            loading: false,
            saving: false
        }
    },
    mounted () {
        this.loadUsers()
    },
    methods: {
        loadUsers () {
            this.loading = true
            this.$api.users.getAllUsers()
                .then((users) => {
                    this.users = users
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('adminUsers.usersTable.usersLoadError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.loading = false
                    })
                })
        },
        onEnable (row) {
            this.saving = true
            this.$api.users.create({ dn: row.dn, sAMAccountName: row.sAMAccountName })
                .then(() => {
                    this.$notify.success(this.$t('adminUsers.usersTable.userSaveSuccess'))
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('adminUsers.usersTable.userSaveError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.saving = false
                        this.loadUsers()
                    })
                })
        },
        onDisable (row) {
            this.saving = true
            this.$api.users.delete(row.id)
                .then(() => {
                    this.$notify.success(this.$t('adminUsers.usersTable.userSaveSuccess'))
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('adminUsers.usersTable.userSaveError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.saving = false
                        this.loadUsers()
                    })
                })
        }
    }
}
</script>
